:root {
    --primary-color: rgb(23, 133, 130);
    --secondary-color: rgb(215, 255, 253);
  
    --background-color: #ffffff;
    --default-color: #444444;
    --heading-color: #012970;
    --accent-color: #4154f1;
    --surface-color: #ffffff;
    --contrast-color: #ffffff;
  }
  
  .blog-slide {
      position: relative;
      margin: auto;
      background: #fff;
      /* box-shadow: 0px 10px 20px rgba(0, 123, 255, 0.5); */
      box-shadow: 0px 0px 20px rgba(23, 133, 130, 0.6);
      border-radius: 40px;
      transition: all 0.3s;
      width: 100%;
      max-width: 600px;
      /* width: 50%; */
      margin-top: 40px;
    }
    
    /* Add-Update-Delete Blog Buttons  */
    .blog-buttons {
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      padding: 12px 20px;
      border-radius: 25px;
      transition: all 0.3s;
    }
    .add-blog-button,
    .add-submit-button {
      border: 2px solid var(--primary-color);
      background-color: var(--primary-color);
      color: var(--secondary-color);
    }
    .add-blog-button-active,
    .add-submit-button:hover,
    .btn-primary.add-submit-button:active {
      border: 2px solid var(--primary-color);
      background-color: transparent;
      color: var(--primary-color);
    }
    .update-blog-button,
    .update-submit-button {
      border: 2px solid #577b8d;
      background-color: #577b8d;
      color: #fff;
    }
    .update-blog-button-active,
    .update-submit-button:hover,
    .btn-primary.update-submit-button:active {
      border: 2px solid #577b8d;
      background-color: transparent;
      color: #577b8d;
    }
    .delete-blog-button,
    .delete-submit-button {
      border: 2px solid #ef4040;
      background-color: #ef4040;
      color: #fff;
    }
    .delete-blog-button-active,
    .delete-submit-button:hover,
    .btn-primary.delete-submit-button:active {
      border: 2px solid #ef4040;
      background-color: transparent;
      color: #ef4040;
    }
    
    /* 
    ---------------------------------------------
    Blog Page style
    --------------------------------------------- 
    */
    .blog-section .section-heading {
      margin-bottom: 40px;
    }
    
    .blog-item {
      background-color: #d27d3c99;
      border-radius: 25px;
    }
    .blogs .blog-item .blog-image {
      position: relative;
    }
    .blogs .blog-item .blog-image img {
      position: absolute;
      top: -70px;
      left: 0px;
      border-radius: 25px;
      width: 100%;
      max-width: 260px;
      height: 200px;
      object-fit: cover;
      object-position: center;
    }
    .blogs .blog-item .blog-content {
      margin-top: 150px;
    }
    .blogs .blog-item .blog-content .blog-date {
      display: block;
      color: #7b7992;
      font-weight: 500;
      font-size: 14px;
    }
    .blogs .blog-item .blog-content .blog-title {
      font-size: 15px;
      font-weight: 700;
      color: #0d0925;
    }
    .blogs .blog-item .blog-content .blog-description {
      color: #4e4a67;
      max-height: 3.2em;
      overflow: hidden;
    }
    
    .blogs .blog-item .blog-content .blog-button {
      display: inline-block;
      background-color: #fff;
      color: var(--primary-color);
      font-size: 14px;
      font-weight: 500;
      height: 40px;
      line-height: 40px;
      padding: 0px 20px;
      border-radius: 20px;
      transition: all 0.3s;
    }
    .blogs .blog-item .blog-content .blog-button:hover,
    .blogs .blog-item .blog-content .blog-button:hover {
      background-color: var(--primary-color);
      color: #fff;
    }
    
    @media screen and (min-width: 768px) {
      .blog-item {
        height: 300px;
      }
      .blogs .blog-item .blog-image img {
        top: 0;
        left: -90px;
      }
      .blogs .blog-item .blog-content {
        margin-top: 0px;
        margin-left: 200px;
      }
    }
    @media screen and (min-width: 992px) {
      .blogs .blog-item .blog-content {
        margin-top: 0px;
        margin-left: -90px;
      }
    }
    /* 
    ---------------------------------------------
    Blog Details style
    --------------------------------------------- 
    */
  
    
  .tags-input {
    display: inline-block;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    box-shadow: 2px 2px 5px #00000033;
    width: 50%;
  }
  
  .tags-input ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .tags-input li {
    display: inline-block;
    background-color: #f2f2f2;
    color: #333;
    border-radius: 20px;
    padding: 5px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 12px;
  }
  
  .tags-input input[type="text"] {
    border: none;
    outline: none;
    padding: 5px;
    font-size: 15px;
  }
  
  .tags-input input[type="text"]:focus {
    outline: none;
  }
  
  .tags-input .delete-button {
    background-color: transparent;
    border: none;
    color: #999;
    cursor: pointer;
    margin-left: 5px;
  }
  
  #blog-edit input{
    color: black;
  }
  
  #blog-edit input::placeholder{
    color: black;
  }
  
  .reactquill{
    color: black;
  }
  
    