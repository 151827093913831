.light-mode {
    background-color: #ffffff;
    color: #000000;
  }
  
  .dark-mode {
    background-color: #000000;
    color: #cbe5ff;
  }

  .dark-mode .contact .contactdesc{
    color: black;
  }

  .dark-mode .contact label{
    color: black;
  }

  .dark-mode .contact p{
    color: black;
  }

/* .light-about h1, .light-about p,.light-about .aboutp h4{
  color: black;
} */

.dark-about h1, .dark-about p,.dark-about .aboutp h4 {
  color: white;
}

/* .light-project h4, .light-project h1{
  color: black;
} */

.dark-project h1, .dark-project p{
  color: black;
}

.dark-Team h1{
  color: white;
}

.dark-Team p{
  color: white;
}

.dark-Team .team-content p {
  color: black;
}

