.dynamic-fields-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
  }
  
  .input-group {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .input-group input[type="text"] {
    width: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .input-group input[type="file"] {
    padding: 8px;
  }
  
  .add-more-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    width:100px;
  }
  
  .add-more-button:hover {
    background-color: #45a049;
   
  }
  
  .delete-button {
    background-color: #f44336;
    color: white;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: #e53935;
  }
  
  .text-orange{
    color: orange;
  }

  .submit-button {
    background-color: green;
    border-color: green;
    width: 100px;
    border-radius: 5px;
    
    align-items: center;
    text-align: center;
  }