
.appointments_table_section {
    padding-bottom: 20px;
}

.table-container {
    overflow-x: auto;
}

#contactFormTable {
    display: table;
    width: auto;
    padding: 30px 100px;
    -webkit-box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.07);
    box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.07);
    width: 100%;
    border-collapse: collapse;
    padding-bottom: 100px;
}

.appointments_table_section h2 {
    color: orange;
    text-align: center;
    padding-bottom: 20px;
}

#contactFormTable th,
#contactFormTable td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
}

#contactFormTable th {
    background-color: orange;
    padding-bottom: 10px;
}

#conatctFormTable tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

#contactFormTable tbody tr:hover {
    background-color: #ddd;
}

#contactFormTable button {
    background-color: #853f91;
    padding: 5px 5px;
}

.date-filters, .filter1 {
    display: flex;
    align-items: center;
}


.filter {
    margin-right: 20px; /* Adjust spacing between elements */
}
