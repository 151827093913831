
#semi {
	height:150px;
	width:550px;
	fill:red;
	z-index: -1;
}

#wheel1 {
	position:absolute;
	bottom:2px;
	left:171px;
	border-radius:50%;
	height:38px;
	width:66px;
	animation:rotateAnimation 4s linear infinite;
	fill:#1e1e1e;
	z-index: 2;
}

#wheel2 {
	position:absolute;
	bottom:2px;
	left:301px;
	border-radius:50%;
	height:38px;
	width:66px;
	animation:rotateAnimation 4s linear infinite;
	fill:#1e1e1e;
	z-index: 2;
}

#wheel3 {
	position:absolute;
	bottom:2px;
	left:345px;
	border-radius:50%;
	height:38px;
	width:66px;
	animation:rotateAnimation 4s linear infinite;
	fill:#1e1e1e;
	z-index: 2;
}

@keyframes rotateAnimation {
	90% {
		bottom:-3px;
	}
	
	100% {
		transform:rotate(-360deg);
		bottom:0;
	}
}



.containerTruck h1 {
	position:absolute;
	top:90px;
	left:170px;
	font-size:.4rem;
}

.ole {
	position:absolute;
	top:-2px;
	left:3px;
}

.rasmussen {
	position:absolute;
	top:4px;
	left:13px;
}

.circle {
	position:absolute;
	bottom:90px;
	left:120px;
	width:2em;
	height:2em;
	border-radius:50%;
	background-color:red;
}

@media only screen and (max-width: 550px) {
	#semi {
		height:100px;
		width:400px;
		fill:red;
		z-index: -1;
	}
	
	#wheel1, #wheel2, #wheel3 {
		height:30px;
		width:30px;
	}
	
	#wheel1 {
		position:absolute;
		bottom:2px;
		left:135px;
		border-radius:50%;
		animation:rotateAnimationSmall 4s linear infinite;
		fill:#1e1e1e;
		z-index: 2;
	}

	#wheel2 {
		position:absolute;
		bottom:2px;
		left:225px;
		border-radius:50%;
		animation:rotateAnimationSmall 4s 1s linear infinite;
		fill:#1e1e1e;
		z-index: 2;
	}

	#wheel3 {
		position:absolute;
		bottom:2px;
		left:255px;
		border-radius:50%;
		animation:rotateAnimationSmall 4s 2s linear infinite;
		fill:#1e1e1e;
		z-index: 2;
	}

	.ole, .rasmussen,.containerTruck h1 {
		font-family: "HighlandGothicFLF";
		color:white;
		font-size:.6rem;
		text-transform:uppercase;
	}

	.containerTruck h1 {
		position:absolute;
		top:65px;
		left:120px;
		font-size:4px;
	}

	.ole {
		position:absolute;
		top:-2px;
		left:3px;
	}

	.rasmussen {
		position:absolute;
		top:1px;
		left:7px;
	}

	.circle {
		position:absolute;
		bottom:37px;
		left:140px;
		width:1em;
		height:1em;
		border-radius:50%;
		background-color:red;
	}

	@keyframes rotateAnimationSmall {
	90% {
		bottom:-1px;
	}
	
	100% {
		transform:rotate(-360deg);
		bottom:0;
	}
}
	
	
}
