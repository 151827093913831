.bottom-wrapd{
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 80svh;
}

.projects {
    height: 440vh; 
   
}


.project-1 {
    height: 100vh;
    z-index: 15;
    padding: 1rem;
    position: sticky;
}

.sticky-wrapd{
      
    margin-top: auto;
    margin-bottom: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 2svh;
}


.sticky-wrapd .projects-item{
   
    bottom: 0;
}

.project-2 {
    border-radius: 10px;
    bottom: 0;
    height: 100vh;
    width: 100%;
    z-index: 14;
    padding: 1rem;
    position: sticky;
}



.project-3 {
    border-radius: 10px;
    bottom: 0;
    height: 100vh;
    width: 100%;
    z-index: 13;
    padding: 1rem;
    position: sticky;
}

.project-4 {
    border-radius: 10px;
    bottom: 0;
    z-index: 12;
    padding: 1rem;
    position: sticky;
    height: 100vh;
}


/* 
.projectcontentfixed{
    position: fixed;
    bottom: 0;
    height: 600px;
    z-index: 0; 
    transition: opacity 0.5s ease;
} */

.projectcontentfixed h1 {
    font-size: 22px;
}

.projectcontentfixed .nav li{
    font-size: 10px;
}

.v2d{ 
    margin-left: 45%;
    z-index: 5;
    
  }

.hiddendemo{
    display: none;
}
 

/* .projectcontentfixed.hiddendemo {
    opacity: 0; 
    display: none;
} */

/* .projectcontentfixed:not(.hiddendemo) {
    opacity: 1; 
} */

.hiddendemo {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
/*   
  .projectcontentfixed {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
   */


  
  .slide-in2 {
    animation: slideIn 0.5s forwards; 
  }
  
/* 
  .projectcontentfixed {
    opacity: 0;  
    transition: opacity 0.5s ease-in-out; 
  }
   */
  .service .visible {
    opacity: 1; 
  } 




  /* Initially hide the description */
.description-text.hide {
  
}

.projectcontentfixed.fixed1 .toggle-btn{
  display: none;
}

.projectcontentfixed.fixed .toggle-btn{
  display: none;
}

/* Show description when visible */
.description-text.show {
  display: block;
}

/* Style the toggle button */
.toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 10px;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .projectcontentfixed.fixed1 .toggle-btn {
    display:block;
  }

  .projectcontentfixed.fixed .toggle-btn {
    display:block;
  }
  .description-text.hide {
    position: relative;
    display: none;
  }

  .description-text.show {
    position: relative;
    display: block;
  }

  .projectcontentfixed.fixed1 .nav li{
    height: 60px;
  }
  
}
