.service-v2-card-title {
    color: #0f2168;
    margin-bottom: 32px;
    font-weight: 600;
    line-height: 96%;
    
  }
  
  .service-v2-card-title.white {
    color: #e9edfd;
  }

  .service-v2-card-details {
    color: #595959;
    font-size: 14px;
    font-weight: 500;
    line-height: 175%;
  }
  
  .service-v2-card-details.white {
    color: #bac7f8;
  }
  
  .service-v2-bottom-wrap {
   
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 80svh;
  }
  
  .service-v2-flex-wrap {
    grid-column-gap: 102px;
    grid-row-gap: 102px;
    display: flex;
    bottom: 0;
  }

  .service-v2-content-wrap {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 392px;
    display: flex;
    bottom: 0;
    margin-top: 80px;
    margin-left: 15%;
  }

  .service-v2-sticky-wrap {
    
    margin-top: auto;
    margin-bottom: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 2svh;
  }
  
  /* .service-v2-main-wrap {
    border-radius: var(--radius-32px);
    flex-direction: column;
    width: 100%;
    height: 773px;
    display: flex;
    position: sticky;
    overflow: hidden;
  } */
  
  .service-v2-single-wrap {
    
    /* background-image: url("https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda0a0_Service%20V2%20Single%20BG%2002.png"); */

    background-repeat: no-repeat;
    background-size: auto;
    justify-content: center;
    align-items: center;
    padding: 208px 64px 94px 159px;
  }
 
  .section1 {
    border-radius: 10px;
    border-color: #060606;
    height: 80vh;
    z-index: 10;
    padding: 1rem;
    width: 100%;
    /* background-image: url("../img/bgtexture6.jpg"); */
   
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #d4d6d9;
    position: sticky;
    } 

    .section1 .commericalContent {
      background-color: white;
      border-radius: 10px;
      margin-top: 100px;
      height: 350px;
      width: 400px;
    }

    .section1 .commericalContent h4{
      margin-top: 15px;
      font-weight: bold;
    }

    .section1 .commericalContent p{
      
      
      text-align: justify;
    }


    .section1 .active .img-fluid,.section2 .active .img-fluid,.section3 .active .img-fluid,
    .section4 .active .img-fluid {
      margin-top: 100px;
    }
  
   .section1 p{
      color: black;
    }
  
    .servi p{
     
      
      text-align: justify;
    }
    
    .servi h2{
      margin-top: 70px;
  
    }
   .section2 {
      border-radius: 10px;
      bottom: 0;
      height: 80vh;
      width: 100%;
      z-index: 7;
      padding: 1rem;
      /* background-image: url("../img/bgtexture2.jpg"); */
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #adc2ac;
      position: sticky;
      
    }

    .section2 .residentalContent{
      background-color: white;
      border-radius: 10px;
      margin-top: 100px;
      height: 350px;
      width: 400px;
    }

    .section2 .residentalContent h4{
      margin-top: 15px;
      font-weight: bold;
    }

    .section2 .residentalContent p{
      text-align: justify;
    }
    
    .section3 {
      border-radius: 10px;
      bottom: 0;
      height: 80vh;
      width: 100%;
      z-index: 6;
      padding: 1rem;
       /* background-image: url("../img/bgtexture3.jpg"); */
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #acacc2;
      position: sticky;
    }

    .section3 .industrialContent {
      background-color: white;
      border-radius: 10px;
      margin-top: 100px;
      height: 350px;
      width: 400px;
    }

    .section3 .industrialContent h4 {
      margin-top: 15px;
      font-weight: bold;
    }

    .section3 .industrialContent p {
      text-align: justify;
    }
    
    .section4 {
        border-radius: 10px;
        bottom: 0;
        z-index: 5;
        padding: 1rem;
        background-color: #acc2ac;
        /* background-image: url("../img/bgtexture4.jpg"); */
        position: sticky;
        height: 80vh;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
     
    }

    .section4 .safetyContent{
      background-color: white;
      border-radius: 10px;
      margin-top: 100px;
      height: 350px;
      width: 400px;
    }

    .section4 .safetyContent h4{
      margin-top: 15px;
      font-weight: bold;
    }

    .section4 .safetyContent p{
      text-align: justify;
    }

/*   
    .section5 {
        bottom: 0;
        z-index: 4;
        height: 100vh;
        width: 100%;
        padding: 1rem;
        background-image: url("https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda077_Folder%2002.png");
        position: sticky;
        background-repeat: no-repeat;
        background-size: auto;
      
      
    }
  
    .section6 {
        bottom: 0;
       z-index: 3;
       height: 100vh;
       width: 100%;
       padding: 1rem;
       background-image: url("https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda076_Folder%2003.png");
       position: sticky;
       background-repeat: no-repeat;
       background-size: auto;
    }
  
    .section7 {
      bottom: 0;
      height: 100vh;
      padding: 1rem;
      position: sticky;
      z-index: 2;
      width: 100%;
      background-repeat: no-repeat;
      background-size: auto;
      background-image: url("https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda078_Folder%2001.png");
      
    } */
  
    .services-svg {
        z-index: -1;
        color: #f3f3f3;
        object-fit: contain;
        height: 40em;
        min-height: 86%;
        position: absolute;
        top: 12%;
        bottom: 0%;
        left: 0%;
        right: auto;
      }
      
      .services-svg._2 {
        opacity: .02;
        left: 0;
      }
      
      .services-svg._3 {
        opacity: .02;
        left: 0;
      }
      
      .services-svg._1 {
        top: 7%;
        left: 8px;
      }
      
      .services-svg._1.main {
        top: 9%;
      }

      .servicontainer3 {
        max-width: 1270px;
       
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
      }

      .service-dot-wrap {
        display: none;
        z-index: 99;
        grid-column-gap: 8px;
        grid-row-gap: 8px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        /* display: flex; */
        position: fixed;
        top: 0%;
        bottom: 0%;
        left: 67px;
        right: auto;
      }
      
      .service-single-dot {
        border-radius: var(--radius-16px);
        background-color: #d9d9d9;
        width: 8px;
        height: 8px;
      }


      .primary-button-wrap {
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        border: 2px solid var(--blue-500);
        background-color: var(--blue-400);
        color: var(--white-200);
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        padding: 10px 12px;
        font-weight: 600;
        line-height: 175%;
        transition: all .5s;
        display: flex;
      }
      
      .primary-button-wrap:hover {
        transform: rotate(3deg);
        box-shadow: 3px 3px #1a38ae;
      }
      
      .primary-button-wrap._02 {
        background-color: var(--white);
        color: #060606;
        border-color: #142c8b;
      }
      
      .primary-button-wrap._02:hover {
        box-shadow: 3px 3px #0f2168;
      }
      
     
      @media screen and (max-width: 991px) {

        .service-v2-content-wrap {
            max-width: 335px;
          }

          .service-v2-content-wrap._01 {
            grid-column-gap: 60px;
            grid-row-gap: 60px;
          }

          .services-svg {
            color: #e9edfd;
            transform: rotate(-90deg);
          }
        
          .services-svg._2, .services-svg._3 {
            opacity: .06;
            top: auto;
            bottom: -33%;
            left: 42%;
            right: 0%;
          }
        
          .services-svg._1 {
            opacity: .6;
            top: auto;
            bottom: -33%;
            left: 42%;
            right: 0%;
          }
        
          .services-svg._1.main {
            top: auto;
            bottom: -33%;
            left: 42%;
            right: 0%;
          }
        }

         
  .section.service-v2 {
    
    z-index: 10;
    background-color: var(--white);
    /* background-image: url("https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65d4b7adfa81c2d4a826aa67_Background%20Light.png"); */
    /* background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100%; */
    padding-top: 64px;
    /* padding-bottom: 132px; */
    position: relative;
  }

  .dark-service-v2 .servip{
    color: white;
  }

  .dark-service-v2 p,.dark-service-v2 hr{
    color: black;
  }

  .light-service-v2 .servip{
    color: black;
  }
  
  .service-v2-lottie-wrap {
    border-radius: var(--radius-16px);
    background-image: url("https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda043_Service%20V2%20Content%20BG.jpg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    height: 450px;
    display: flex;
    overflow: hidden;
    margin-top: 120px;
  }

  .service-v2-lottie-wrap._02 {
    background-image: url("https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda05a_Service%20V2%20Single%20Image%20Bg%20Black.jpg");
  }
  
  .service-v2-lottie-wrap._03 {
    background-image: url("https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda059_Service%20V2%20Single%20Image%20Bg%20Blue.jpg");
  }
  
  .service-v2-lottie-wrap._05 {
    background-image: url("https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda05a_Service%20V2%20Single%20Image%20Bg%20Black.jpg");
  }
  
  .service-v2-lottie-wrap._06 {
    background-image: url("https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda059_Service%20V2%20Single%20Image%20Bg%20Blue.jpg");
  }
  
  .service-v2-lottie-wrap .CloudMigration{
  
   
   
   
  }

  @media (max-width: 768px) {
   
    .service-v2-flex-wrap {
        display: flex;
        flex-direction: column;
        
    }

  

    .section1, .section2, .section3, .section4 {
      height: auto;
    }
    
    .service-v2-single-wrap .col-lg-6 {
        width: 100%;
    }

    .commericalContent, .residentalContent, .industrialContent, .safetyContent {
       
        width:80%;
    }

    .section1 .service-v2-flex-wrap .commericalContent{
      width: 100%;
      
    }

    .section2 .service-v2-flex-wrap .residentalContent {
      width: 100%;
    }

    .section3 .service-v2-flex-wrap .industrialContent {
      width: 100%;
    }

    .section4 .service-v2-flex-wrap .safetyContent {
      width: 100%;
    }

    .service-v2-single-wrap img {
        width: 100%;
        height: auto;
    }

    .service-v2-single-wrapd img {
      width: 100%;
      height: auto;
    }

    .service-v2d{
      height: 100%;
      margin-top: 500px;
    }

   .section1d {
    height: auto;
   }

   .service-v2-flex-wrapd{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
   }

   .service-v2-flex-wrapd img{
    width: 100%;
   }

   .service-v2-single-wrapd{
    width: 100%;
   }

   .section1d .projects-content {
    width: 100%;
   }

   .section1d .projects-content1,.section1d .projects-content2, .section1d .projects-content3 {
    width: 100%;
   }

   .service-v2-single-wrapd .service-v2-flex-wrapd .projects-content{
    width: 85%;
    align-items: center;
    text-align: center;
    
   }

   .service-v2-single-wrapd .service-v2-flex-wrapd .projects-content1{
    width: 85%;
    align-items: center;
    text-align: center;
  }

}
