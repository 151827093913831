#preloader-active {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color:#ffffff;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 50%;
  /* box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.preloader-circle {
  width: 100px;
  height: 100px;
  border-style: solid;
  border-width: 1px;
  border-top-color: #ff2121;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-radius: 50%;
  box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
  animation: rotate 2s linear infinite;
}

.preloader-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.preloader-img img {
  max-width: 55px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media (max-width: 767px) {
  .preloader {
    width: 60px;
    height: 60px;
  }

  .preloader-circle {
    width: 60px;
    height: 60px;
  }

  .preloader-img img {
    max-width: 50px;
  }
}