
.TeamSection .cardTeam {
    margin-top: 50px;
    max-width: 400px;
    margin: auto;
    overflow-y: auto;
    position: relative;
    z-index: 1;
    overflow-x: hidden;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    transition: 0.3s;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2);
  }

  .TeamSection {
    height: 600px;
    margin-top: 100px;
  }

  .TeamSection .row {
    flex-direction: row !important;
  }
  
  .TeamSection .cardTeam[data-state="#about"] {
    height: 500px;
    .cardTeam-main {
      padding-top: 0;
    }
  }
  
  
  .cardTeam.is-active {
    .cardTeam-header {
      height: 80px;
    }
  
    .cardTeam-cover {
      height: 100px;
      top: -50px;
    }
  
    .cardTeam-avatar {
      transform: none;
      left: 20px;
      width: 50px;
      height: 50px;
      bottom: 10px;
    }
  
    .cardTeam-fullname,
    .cardTeam-jobtitle {
        padding-top: 20px;
      left: 86px;
      transform: none;
    }
  
    .cardTeam-fullname {
      bottom: 18px;
      font-size: 19px;
    }
  
    .cardTeam-jobtitle {
        
      bottom: 16px;
      letter-spacing: 1px;
      font-size: 10px;
    }
  }
  
  .cardTeam-header {
    position: relative;
    display: flex;
    height: 200px;
    flex-shrink: 0;
    width: 100%;
    transition: 0.3s;
  
    * {
      transition: 0.3s;
    }
  }
  
  .cardTeam-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    height: 160px;
    top: -20%;
    left: 0;
    will-change: top;
    background-size: cover;
    background-position: center;
    filter: blur(30px);
    transform: scale(1.2);
    transition: 0.5s;
  }
  
  .cardTeam-avatar {
    width: 100px;
    height: 100px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
    position: absolute;
    
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-64px);
  }

  
  
  .cardTeam-fullname {
    color: black;
    position: absolute;
    bottom: 0;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    transform: translateY(-10px) translateX(-50%);
    left: 50%;
  }
  
  .cardTeam-jobtitle {
    
    position: absolute;
    color: black;
    bottom: 0;
    font-size: 11px;
    white-space: nowrap;
    font-weight: 500;
    opacity: 0.7;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-7px);
  }
  
  .cardTeam-main {
    position: relative;
    flex: 1;
    display: flex;
    padding-top: 10px;
    flex-direction: column;
  }
  
  .cardTeam-subtitle {
    color: black;
    font-weight: 700;
    font-size: 13px;
    margin-bottom: 8px;
  }
  
  .cardTeam-content {
    padding: 20px;
  }
  
  .cardTeam-desc {
    line-height: 1.6;
    color: #636b6f;
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
  }
  
  .cardTeam-social {
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 30px;
    svg {
      fill: rgb(165, 181, 206);
      width: 16px;
      display: block;
      transition: 0.3s;
    }
    a {
      color: #8797a1;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      background-color: rgba(93, 133, 193, 0.05);
      border-radius: 50%;
      margin-right: 10px;
  
      &:hover {
        svg {
          fill: darken(rgb(165, 181, 206), 20%);
        }
      }
  
      &:last-child {
        margin-right: 0;
      }
    }
  }
  
  .cardTeam-section {
    display: none;
    &.is-active {
      display: block;
      animation: fadeIn 0.6s both;
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translatey(40px);
    }
    100% {
      opacity: 1;
    }
  }
  
  
  .cardTeam-contact {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #6f6f7b;
    font-family: "DM Sans", sans-serif;
    line-height: 1.6;
    cursor: pointer;
  
    & + & {
      margin-top: 16px;
    }
  
    svg {
      flex-shrink: 0;
      width: 30px;
      min-height: 34px;
      margin-right: 12px;
      transition: 0.3s;
      padding-right: 12px;
      border-right: 1px solid #dfe2ec;
    }
  }
  

  .cardTeam-social a .facebookteam {
    fill: blue;
  }

  .cardTeam-social a .twitterteam {
    fill: #87CEEB;
  }

  .cardTeam-social a .instagramteam {
    fill: #833AB4;
  }

  .cardTeam-social a .linkedteam {
    fill: #0077B5;
  }

  @media (max-width: 768px) {
    .TeamSection{
      height: 100%;
    }
    .cardTeam{
      margin-top: 50px;
      width: 80%;
    }
  }