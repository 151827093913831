.testimony-section {
    position: relative;
    background-color: white;
    padding: 20px;
    
  }
  
  .testimony-section .owl-carousel {
    margin: 0;
  }
  
  .testimony-wrap {
    display: block;
    position: relative;
  }
  
  .testimony-wrap .user-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    margin-top: -75px;
    margin: 0 auto;
  }
  
  .testimony-wrap .user-img .quote {
    position: absolute;
    bottom: -20px;
    left: 50%;
    width: 40px;
    height: 40px;
    transform: translateX(-50%);
    background: #A890FE;
    border-radius: 50%;
  }
  
  .testimony-wrap .user-img .quote i {
    color: #fff;
  }
  
  .testimony-wrap .name {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0;
    color: #000;
  }
  
  .testimony-wrap .position {
    font-size: 13px;
  }

  .dark-testimonial .testipara{
    color: black;
  }

 .testimonial{
  position: relative;
  z-index: 10;
 }

 