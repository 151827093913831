.serviceanimation {
    height: 400vh; 
    margin-top: 50px;
}

.bottom-wrap{
  
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100svh;
    position: static;

}

.projectd-1 {
    height: 90vh;
    z-index: 15;
    padding: 1rem;
    position: sticky;
    
}


.projectd-2 {
    
    border-radius: 10px;
    bottom: 0;
   
    height: 90vh;
    width: 100%;
    z-index: 14;
    padding: 1rem;
    position: sticky;
}

.projectd-3 {
    border-radius: 10px;
    bottom: 0;
    height: 90vh;
    width: 100%;
    z-index: 13;
    padding: 1rem;
    position: sticky;
}

.projectd-4 {
    border-radius: 10px;
    bottom: 0;
    z-index: 12;
    padding: 1rem;
    position: sticky;
    height: 90vh;
}

.service .sticky-wrap{
      
    margin-top: auto;
    margin-bottom: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 6svh;
}

.v2{ 
    margin-left: 45%;
    z-index: 5;
    padding-top: 40px;
  }

.sticky-wrap .projects-item{
   
    bottom: 0;
}


.container-fluid.banner {
    position: relative;
    z-index:50;
}


.owl-nav .owl-prev,
.owl-nav .owl-next {
  background-color: orange;
  color: #fff; 
  border-radius: 50%;
  padding: 10px;
  font-size: 20px;
  transition: background-color 0.3s ease; 
}

.owl-nav .owl-prev:hover,
.owl-nav .owl-next:hover {
  background-color: orange;
}


.owl-nav .owl-prev {
  position: absolute;
  left: -30px; 
  top: 40%;
}

.owl-nav .owl-next {
  position: absolute;
  right: -30px;
  top: 40%;
}



.projectcontentfixed {
  position:fixed;
 
  height: 600px;
  width: 550px;
  z-index: 0; 
}

.projectcontentfixed.fixed {
  position: fixed;
  z-index: 0;
  bottom: 0;
  height: 550px;
}


.projectcontentfixed.fixed1 {
  position: fixed;
  z-index: 0;
  bottom: 0;
  height: 600px;
}



@media only screen and (max-width: 768px) {
  .bottom-wrap {
    height: auto;
    width: 100%; 
    padding: 1rem;
  }

  .projectcontentfixed{
    width: 400px;
    
  }

  .projectcontentfixed.fixed{
    width: 400px;
    bottom: 0;
    padding-top: 320px;
    
  }

  .projectcontentfixed.fixed p{
    font-size: 8px;
  }

  .projectcontentfixed.fixed1{
    width: 400px;
    bottom: 0;
    padding-top: 300px;
  }

  .projectcontentfixed.fixed1 .nav li{
      margin-top: 5px;
  }

  .projectcontentfixed.fixed1 .nav{
    margin-top: 20px;
  }

  .projectcontentfixed.fixed1 p{
    font-size: 8x;
  }

  .projects .nav-item a.active {
    height: 60px;
  }

  .service{
    width: 100%;
    left: 0;
  }

  .service .projectcontentfixed h4{
    text-align: center;
  }

  .service .projectcontentfixed .nav li {
    height: 50px;
    flex-direction: row;
  
  }

  .service .nav-item a.active{
    height: 50px;
  }

  .v2{
    position: static;
    margin-top: -100px;
    margin-left: 0px;
    width: 100%;
    height: 200px !important;
  }

  .projectd-2{
    
  }

 
 

  .v2d{
    position: static;
    margin-top: 0px;
    margin-left: 0px;
    width: 100%;
  }

  .bottom-wrap{
    top: 0;
    width: 100%;
    left: 0;
  }

  .sticky-wrap{
    top: 0;
    width: 100%;
    left: 0;
  }

  .service {
    padding: 0 0px;
  }

  .tab-content {
    margin: 0;
    padding: 0;
  }

  .projects{
    position: relative;
    z-index: 9999;
  }

  .projectcontentfixed.fixed p{
    font-size: 14px;
  }

  .projectcontentfixed.fixed li{
    margin-top: 4px;
  }

  .v2d p{
    text-align: justify;
    font-size: 12px;
  }

  .testimonial{
    position: relative;
    z-index: 9999;
  }

  .projectd-1{
    height: 60%;
  }

  .service .projectcontentfixed.fixed .nav-item {
    width: 40% !important;
    display: inline-block; 
    box-sizing: border-box;
    margin: 1%;
  }

  .service .projectcontentfixed.fixed .nav {
    text-align: center;
  }

  .projects .projectcontentfixed.fixed1 .nav-item{
    width: 40% !important;
    display: inline-block; 
    box-sizing: border-box;
    margin: 1%;
  }

  .projects .projectcontentfixed.fixed1 .nav-item span{
    font-size: 12px;
  }

  .projects .projectcontentfixed.fixed1 .nav-item .projects-div{
    height: 40px;
  }
  .projects .projectcontentfixed.fixed1 .nav-item img{
    height: 30px;
  }

  .header-top nav .logoimg{
    height: 40;
  }

  nav .headertitlem{
    font-size: 12 !important;
  }
  .welcomesliderheader1 p{
    text-align: justify;
  }
  .about h1{
    font-size: 20px;
  }

  .about h4{
    font-size: 16px;
  }

  .about p{
    text-align: justify;
    
  }

  .about-point p{
    font-size: 10px;
  }
  .banner .container{
    margin-left: 20px;
  }

  .header-top::after {
    display: none;
  }
}

/* above 768px device*/
@media only screen and (min-width: 768px) {
  
  .projectcontentfixed.fixed ul{
    margin-top: 50px;
  }

  .projectcontentfixed.fixed1 ul{
    margin-top: 30px;
  }

  .projectcontentfixed.fixed li{
    margin-top: 20px;
  }

  .projectcontentfixed.fixed1 li{
    margin-top: 20px;
  }

  .projectcontentfixed.fixed ul a{
    font-size: 14px;
  }

  .v2{
    width: 50%;
    height: 350px !important;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
  }

  .v2 .projectd-1{
    height: 30% !important;
  }

  .v2 .projectd-2{
    height: 40%;
  }

  .v2 .projectd-3{
    height: 40%;
  }

  .v2 .projectd-3{
    height: 40%;
  }
}

@media only screen and (max-width: 480px) {
  .navbar .headertitlem {
    font-size: 15px !important;
  }

  .welcomedesc{
    text-align: justify;
  }
/* 
  .v2 .projectd-1 img{
    height: 40%;
  }

  .v2 .projectd-2 img{
    height: 40%;
  }

  .v2 .projectd-3 img{
    height: 40%;
  }

  .v2 .projectd-4 img{
    height: 40%;
  } */


  

  .v2 p{
    text-align: justify;
  }

  .v2 img{
      height: 150px;
  }

  .v2d img{
    height: 150px;
  }
  
  .v2d p{
    font-size: 10px;
  }

  .welcomesliderheader1 p{
    text-align: justify;
  }

  .about h1{
    font-size: 20px;
  }

  .about h4{
    font-size: 16px;
  }

  .about p{
    text-align: justify;
    
  }

  .about-point p{
    font-size: 10px;
  }

  .banner .container{
    margin-left: 20px;
  }
 
  .header-top::after {
    display: none;
  }

  .header-top .navbar .navbar-brand{
    left: 0;
  }

 .testimonial .testipara{
  text-align: justify;
 }

 .footer p.footerdescription {
  text-align: justify;
 }
}