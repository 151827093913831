.founder-image {
    height: auto;
    border-radius: 0.75rem;
    margin-left: 200px;
}

.founder-image img {
    
    width: 60%;
    height: 350px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 80%;
}

.foundersection1 {
    margin-top: 0;
    height: 550px;
    /* background: #6965F8;
    background: radial-gradient(at right center, #6965F8, #0A5A76); */
}

.founderinfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25rem;
    height: 350px;
    aspect-ratio: 3 / 4;
    background-color: rgba(252, 252, 254, 0.1);
    backdrop-filter: blur(7px);
    border: 2px solid rgba(250, 250, 250, 0.2);
    border-radius: 0.75rem;
    padding: 10px;
    padding-top: 30px;
}

.glassmorphism1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25rem;
    height: 220px;
    aspect-ratio: 3 / 4;
    background-color: rgba(252, 252, 254, 0.1);
    backdrop-filter: blur(7px);
    border: 2px solid rgba(250, 250, 250, 0.2);
    border-radius: 0.75rem;
}



/* Glassmorphism style for achievements and qualification */
.glassmorphism {
    background-color: rgba(252, 252, 254, 0.1);
    backdrop-filter: blur(10px); /* Extra blur applied */
    border: 2px solid rgba(250, 250, 250, 0.2);
    border-radius: 0.75rem;
   
    height: 400px;
    margin-bottom: 20px;
    margin-left: 350px;
    width: 45%;
    margin-top: 50px;
}

/* Text alignment and icon styling */
.glassmorphism h5 {
    display: flex;
    align-items: center;
    justify-content: center;
    background: -webkit-linear-gradient(0deg, #f76591, #ffc16f);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.glassmorphism1 h5 {
    display: flex;
    align-items: center;
    justify-content: center;
    background: -webkit-linear-gradient(0deg, #f76591, #ffc16f);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.glassmorphism h5 svg {
    margin-right: 10px; /* Spacing between icon and text */
}

.glassmorphism1 h5 svg {
    margin-right: 10px; /* Spacing between icon and text */
}

.glassmorphism ul {
    list-style-type: none;
    padding-left: 0;
}

.glassmorphism li {
    text-align: left;
    color: black;
    margin-bottom: 5px;
}

.glassmorphism p {
    text-align: center;
    color: black;
}

.glassmorphism1 p {
    text-align: center;
    color: black;
}

/* Override default text alignment */
/* .founderinfo h5, .founderinfo h6 {
    text-align: center;
    color: black;
} */


.dark-foundersection1 h5, .dark-foundersection1 h6{
  
    color: white;
    text-align: justify;
}

.light-foundersection1 h5, .light-foundersection1 h6{
   
    color: white;
    text-align: justify;
}

.light-foundersection1{
    background-color: black;
}

.light-foundersection1 h5{
    font-size: 15px;
}

.dark-foundersection1 h5{
    font-size: 15px;
}

@media (max-width: 768px) {
    .founder-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .founder-image {
        margin-left: 0;
        margin-bottom: 20px;
    }

    .founder-image img {
        margin-left: 35%;
        width: 30%; /* Make image responsive */
        height: 5%;
    }

    .founderinfo {
        height: 280px;
        width: 100%;
        padding: 5px;
        align-items: center;
    }
}
