
/*** Spinner Start ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}
/*** Spinner End ***/

.back-to-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    transition: 0.5s;
    z-index: 99;
}


/*** Button Start ***/
.btn {
    font-weight: 600;
    transition: .5s;
}

.btn-square {
    width: 32px;
    height: 32px;
}

.btn-sm-square {
    width: 34px;
    height: 34px;
}

.btn-md-square {
    width: 40px;
    height: 40px;
}

.btn-lg-square {
    width: 46px;
    height: 46px;
}

.btn-xl-square {
    width: 56px;
    height: 56px;
}

.btn-square,
.btn-sm-square,
.btn-md-square,
.btn-lg-square,
.btn-xl-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}


.btn.btn-primary {
    color: var(--bs-white);
    border: none;
}

.btn.btn-primary:hover {
    background: var(--bs-dark);
    color: var(--bs-white);
}

.btn.btn-light {
    color: var(--bs-primary);
    border: none;
}

.btn.btn-light:hover {
    color: var(--bs-white);
    background: var(--bs-primary);
}

/*** Navbar Start ***/
.header-top .topbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    vertical-align: middle;
    margin-left: 8px;
    color: var(--bs-primary);
}

.header-top .topbar .dropdown .dropdown-menu {
    transform: rotateX(0deg);
    visibility: visible;
    background: var(--bs-light);
    padding-top: 12px;
    border: 0;
    transition: .5s;
    opacity: 1;
}

.header-top {
    position: sticky;
    top: 0;
    z-index: 99999;
    background-color: white;
    box-shadow: 0 4px 2px -2px orange;
  }

.header-top::after {
    content: "";
    position: absolute;
    overflow: hidden;
    width: 20%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--bs-dark);
    z-index: 1;
}

@media (min-width: 992px) {
    .header-top::before {
        content: "";
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 45px;
        top: 0;
        right: 0;
        background: var(--bs-dark);
        z-index: -1;
    }

    .navbar-brand-2 {
        display: none;
    }

    
}

@media (max-width: 991px) {
    .header-top {
        max-height: 80px;
    }

    .navbar {
        justify-content: space-between;
    }

    .header-top .container {
        max-height: 80px;
    }

    .navbar-brand {
        display: none;
    }

    .navbar-brand-2 {
        position: relative;
        overflow: hidden;
        width: 200px;
        height: 100%;
        padding: 0;
        display: flex;
        align-items: center;
        z-index: 99;
    }

    .navbar-brand-2::before {
        content: "";
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 600px;
        top: -200px;
        right: 18px;
        background: var(--bs-dark);
        transform: rotate(-18deg);
        z-index: -1;
    }

    .navbar-brand-2::after {
        content: "";
        position: absolute;
        overflow: hidden;
        width: 5px;
        height: 600px;
        top: -200px;
        right: 18px;
        background: var(--bs-primary);
        transform: rotate(-18deg);
        z-index: -1;
    }

    .navbar.navbar-light {
        height: 100%;
        padding: 0;
    }

    .navbar .navbar-collapse {
       
    }

}

.nav-bar .navbar {
    z-index: 9;
}

.navbar-light .navbar-brand img {
    max-height: 60px;
    transition: .5s;
}

.nav-bar .navbar-light .navbar-brand img {
    max-height: 50px;
}

.navbar .navbar-nav .nav-item .nav-link {
    padding: 0;
}

.navbar .navbar-nav .nav-item {
    display: flex;
    align-items: center;
    padding: 0;
    margin-right: 20px;
}

.navbar-light .navbar-nav .nav-item:hover,
.navbar-light .navbar-nav .nav-item.active,
.navbar-light .navbar-nav .nav-item:hover .nav-link,
.navbar-light .navbar-nav .nav-item.active .nav-link {
    color: var(--bs-primary);
}

@media (max-width: 991px) {
    .navbar {
        padding: 20px 0;
    }

    .navbar .navbar-nav .nav-link {
        padding: 0;
    }

    .navbar .navbar-nav .nav-item {
        display: flex;
        padding: 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .dropdown-menu{
        width: 80%;
       
    }

    .navbar .navbar-nav .nav-btn {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .navbar .navbar-nav .nav-btn ,.dropdown-menu .dropdown-item{
        justify-content: center;
        text-align: center;
    }

    .navbar .navbar-nav {
       
        width: auto;
        display: flex;
        margin-top: 20px;
        padding-bottom: 20px;
        
        background: var(--bs-light);
    }

    .navbar .navbar-nav .nav-btn {
        display: flex;
        flex-direction: column;
    }

    .navbar .navbar-nav .nav-btn a.btn {
        margin-right: 15px;
    }

    .navbar.navbar-expand-lg .navbar-toggler {
        padding: 10px 15px;
        border: 1px solid var(--bs-primary);
        color: var(--bs-primary);
    }
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    vertical-align: middle;
    margin-left: 8px;
}

.dropdown .dropdown-menu .dropdown-item:hover {
    background: var(--bs-primary);
    color: var(--bs-white);
}

@media (min-width: 992px) {
    .navbar {
        padding: 20px 0;
    }

    .navbar .nav-btn {
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .navbar .navbar-nav {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--bs-light);
        border-radius: 10px;
    }

    .navbar .navbar-nav .nav-btn {
        width: 100%;
        display: flex;
        margin-left: auto;
    }

    .navbar .nav-item .dropdown-menu {
        display: block;
        visibility: hidden;
        top: 100%;
        transform: rotateX(-75deg);
        transform-origin: 0% 0%;
        border: 0;
        transition: .5s;
        opacity: 0;
    }

    .navbar .nav-item:hover .dropdown-menu {
        transform: rotateX(0deg);
        visibility: visible;
        margin-top: 19px;
        background: var(--bs-white);
        transition: .5s;
        opacity: 1;
    }

    .header-top .navbar-brand {
        position: relative;
        overflow: hidden;
        padding: 40px 50px 40px 0;
        z-index: 99;
    }
    
    .navbar-brand::before {
        content: "";
        position: absolute;
        overflow: hidden;
        width: 300px;
        height: 600px;
        top: -200px;
        right: 18px;
        background: var(--bs-dark);
        transform: rotate(-18deg);
        z-index: -1;
    }
    
    .navbar-brand::after {
        content: "";
        position: absolute;
        overflow: hidden;
        width: 5px;
        height: 600px;
        top: -200px;
        right: 18px;
        background: var(--bs-primary);
        transform: rotate(-18deg);
        z-index: -1;
    }
}
/*** Navbar End ***/


/*** Carousel Hero Header Start ***/
.hero-section {
   
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 700px;
    display: flex;
    align-items: center;
    position: relative;
   
}

.hero-bg-half-1 {
    /* background-image: url(./img/NewImage-3.jpg); */
    clip-path: polygon(37% 0%, 100% 0%, 100% 100%, 0% 100%);
    filter: grayscale(10%);
    transform: scale(100% - 50%);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 58%;
    height: 100%;
    right: 0;
    top: 0;
}

.hero-bg-half-2 {
    background-image: url(./img/NewImage-12.jpg);
    clip-path: polygon(37% 0%, 100% 0%, 100% 100%, 0% 100%);
    filter: grayscale(10%);
    transform: scale(100% - 50%);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 58%;
    height: 100%;
    right: 0;
    top: 0;
}

.hero-shape-1 {
    background-color: var(--bs-primary);
    width: 150px;
    height: 50%;
    position: absolute;
    left: 54%;
    top: 0;
    transform: translateY(-50%) skew(-26deg, 0deg);
    position: relative;
}

.hero-shape-1:before {
    background-color: var(--bs-dark);
    content: "";
    width: 50%;
    height: 80%;
    position: absolute;
    right: 0;
    top: 0;
}

.hero-shape-2 {
    background-color: var(--bs-dark);
    width: 150px;
    height: 50%;
    position: absolute;
    right: -43%;
    bottom: 0;
    transform: translateY(50%) skew(-25deg, 0deg);
    position: relative;
    z-index: 1;
}

.hero-shape-2::after {
    background-color: var(--bs-primary);
    content: "";
    width: 75px;
    height: 80%;
    position: absolute;
    right: 0;
    bottom: 0;
}

/*** Carousel Hero Header Start ***/

.header-carousel .header-carousel-item {
    height: 700px;
    
     
}

.welcomeslideheader1{
    position: relative;
    z-index: 9999;
    overflow: hidden;
   
}

.dark-welcomeslideheader1{
    background-color: black;
}

.light-welcomeslideheader1{
    background-color: white;
}

.Projectanimdivh{
    position: relative;
    z-index: 999;
}

.header-carousel{
    position: relative;
    
}

.header-carousel .owl-nav .owl-prev,
.header-carousel .owl-nav .owl-next {
    position: absolute;
    width: 60px;
    height: 60px;
    background: var(--bs-white);
    color: var(--bs-primary);
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
}

.header-carousel .owl-nav .owl-prev {
    bottom: 30px;
    left: 0;
}
.header-carousel .owl-nav .owl-next {
    bottom: 30px;
    right: 0;
}

.header-carousel .owl-nav .owl-prev:hover,
.header-carousel .owl-nav .owl-next:hover {
    box-shadow: inset 0 0 65px 0 var(--bs-primary);
    color: var(--bs-dark);
}

.header-carousel .header-carousel-item .carousel-caption {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    align-items: center;
    z-index: 9;
}

.each-slide-effect {
    background-size: cover;
    background-position: center;
    min-height: 500px;
  }
  

@media (max-width: 991px) {
    .header-carousel .header-carousel-item .carousel-caption {
        padding-top: 45px;
    }

    .header-carousel.owl-carousel,
    .header-carousel .header-carousel-item {
        height: 700px;
    }
}
/*** Carousel Hero Header End ***/


/*** Single Page Hero Header Start ***/
.bg-breadcrumb {
    position: relative;
    overflow: hidden;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(./img/JVS.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 0 60px 0;
    transition: 0.5s;
}

.bg-breadcrumb .breadcrumb {
    position: relative;
}

.bg-breadcrumb .breadcrumb .breadcrumb-item a {
    color: var(--bs-white);
}
/*** Single Page Hero Header End ***/


/*** Banner Start ***/
.banner {
    position: relative;
    overflow: hidden;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(./img/bgtexture4.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.light-banner{
    background-color: white;

}

.dark-banner{
    background-color: black;
}

.banner .container {
    position: relative;
    z-index: 99;

}

.banner::after {
    content: "";
    position: absolute;
    width: 100px;
    height: 500px;
    top: -200px;
    left: 0;
    background: var(--bs-primary);
    transform: rotate(45deg);
    z-index: 1;
}

.banner::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 500px;
    bottom: -200px;
    right: 0;
    background: var(--bs-primary);
    transform: rotate(45deg);
    z-index: 1;
}

.banner .banner-design-1 {
    position: absolute;
    width: 30px;
    height: 500px;
    top: -165px;
    left: 0;
    background: var(--bs-dark);
    transform: rotate(45deg);
    z-index: 2;
}

.banner .banner-design-2 {
    position: absolute;
    width: 30px;
    height: 500px;
    bottom: -165px;
    right: 0;
    background: var(--bs-dark);
    transform: rotate(45deg);
    z-index: 2;
}
.banner .contactwithmecontainer h1{
    font-size: 10px !important;
}
/*** Banner End ***/

/*** Service Start ***/
.service .nav .nav-item {
    width: 75%;
    border: 1px solid var(--bs-primary);
    background: var(--bs-light);
}

.service .nav .nav-item a {
    display: flex;
    justify-content: center;
}


.owl-stage-outer {
    margin-right: -1px;
}
.service .nav-item a.active {
    background: var(--bs-primary);
   
}

.service .nav-item a span {
    color: var(--bs-dark);
}

.service .nav-item a.active span {
    color: var(--bs-white);
}

.service-carousel .owl-nav .owl-prev,
.service-carousel .owl-nav .owl-next {
    position: absolute;
    padding: 10px 35px;
    border: 1px solid var(--bs-primary);
    color: var(--bs-dark);
    background: var(--bs-light);
    transition: 0.5s;
}

.service-carousel .owl-nav .owl-prev:hover,
.service-carousel .owl-nav .owl-next:hover {
    background: var(--bs-primary);
    color: var(--bs-white);
}

@media (min-width: 992px) {
    .service-carousel .owl-nav .owl-prev {
        top: 0;
        left: -115px;
    }
    
    .service-carousel .owl-nav .owl-next {
        bottom: 0;
        left: -115px;
    }
}

@media (max-width: 991px) {
    .owl-stage-outer {
        margin-bottom: 70px;
    }
    .service-carousel .owl-nav .owl-prev {
        bottom: -70px;
        left: 0;
    }

    .service-carousel .owl-nav .owl-next {
        bottom: -70px;
        right: 0;
    }

    .service .nav {
        display: flex;
        justify-content: center;
    }

    .footer .footer-item h3, .footer .footer-item p{
        text-align: center;
    }

    .footer .footer-item h4{
        text-align: center;
    }

    .footer .footer-item a{
        text-align: center;
    }

}
/*** Service End ***/

/*** Projects Start ***/
.projects .nav-item {
    box-shadow: 0 0 30px rgba(0, 0, 0, .09);
}

.projects-item .projects-content {
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);
}

.projects .nav-item a.active {
    background: var(--bs-primary);
}

.projects .nav-item a span {
    color: var(--bs-dark);
}

.projects .nav-item a.active span {
    color: var(--bs-white);
}

.projects .nav-item a.active .projects-icon {
    /* background: var(--bs-dark) !important; */
}

.projects .nav-item a.active .projects-icon span {
    color: var(--bs-primary);
}
/*** Projects End ***/


/*** Blog Start ***/
.blog .blog-item {
    height: 100%;
    background: var(--bs-light);
}

.blog .blog-item .blog-img {
    position: relative;
    overflow: hidden;
}

.blog .blog-item .blog-img::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    display: flex;
    background: rgba(246, 138, 10, .3);
    transition: 0.5s;
}

.blog .blog-item:hover .blog-img::after {
    width: 100%;
    height: 100%;
}

.blog .blog-item .blog-img img {
    transition: 0.5s;
}

.blog .blog-item:hover .blog-img img {
    transform: scale(1.2);
}

.blog .blog-item .blog-heading {
    position: relative;
    background: var(--bs-white);
}

.blog .blog-item .blog-heading a.h4 {
    position: relative;
    width: 100%;
    display: inline-flex;
    transition: 0.5s;
    z-index: 2;
}

.blog .blog-item .blog-heading::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 100%;
    right: 0;
    bottom: 0;
    transition: 0.5s;
    z-index: 1;
}

.blog .blog-item:hover .blog-heading::after {
    width: 100%;
    background: var(--bs-primary);
}

.blog .blog-item:hover .blog-heading a.h4 {
    color: var(--bs-white);
}

.blog .blog-item:hover .blog-heading a.h4:hover {
    color: var(--bs-dark);
}
/*** Blog End ***/

/*** Team Start ***/
.team .team-item .team-img {
    position: relative;
    overflow: hidden;
}

.team .team-item .team-img img {
    transition: 0.5s;
}

.team .team-item:hover .team-img img {
    transform: scale(1.1);
}

.team .team-item .team-img .team-icon {
    position: absolute;
    bottom: 20px; right: -100%;
    z-index: 9;
    transition: 0.5s;
}

.team .team-item:hover .team-img .team-icon {
    right: 25px;
}

.team .team-item .team-img::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    background: rgba(246, 138, 10, .2);
    transition: 0.5s;
    z-index: 1;
}

.team .team-item:hover .team-img::after {
    height: 100%;
}

.team .team-item .team-content {
    transition: 0.5s;
}

.team .team-item:hover .team-content {
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
}
/*** Team End ***/

/*** FAQs Start ***/
.faq-section .accordion .accordion-item {
    margin-bottom: 20px;
    box-shadow: 0 0 30px rgba(0, 0, 0, .08);
    border: none;
}

.faq-section .accordion .accordion-item .accordion-header .accordion-button {
    color: var(--bs-white);
    background: rgba(246, 138, 10, .9);
    font-size: 18px;
}

.faq-section .accordion .accordion-item .accordion-header .accordion-button.collapsed {
    color: var(--bs-dark);
    background: var(--bs-light);
}
/*** FAQs End ***/

/*** Testimonial Start ***/
.testimonial-carousel .owl-stage-outer {
    margin-right: -1px;
}

.testimonial .testimonial-item {
    position: relative;
    margin-top: 35px;
}

.testimonial .testimonial-item .customer-text {
    border: 1px solid var(--bs-primary);
    border-top: none;
}

.testimonial .testimonial-item .testimonial-quote {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    right: 25px;
    transform: translateY(-50%);
    color: var(--bs-dark);
    background: var(--bs-primary);
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonial .testimonial-item .testimonial-inner {
    display: flex;
    align-items: center;
    background: var(--bs-white);
}

.testimonial .testimonial-item .testimonial-inner img {
    width: 100px; 
    height: 100px;
    border: 2px solid var(--bs-primary);
}

.testimonial-carousel .owl-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.testimonial-carousel .owl-dots .owl-dot {
    width: 30px;
    height: 30px;
    margin: 30px 10px 0 10px;
    background: var(--bs-white);
    transition: 0.5s;
}

@media (max-width: 991px) {
    .testimonial-carousel .owl-dots .owl-dot {
        margin: 0 10px 0 10px;
    }
}

.testimonial-carousel .owl-dots .owl-dot.active {
    width: 30px;
    height: 30px;
    background: var(--bs-primary);
    transition: 0.5s;
}

.testimonial-carousel .owl-dots .owl-dot span {
    position: relative;
    margin-top: 50%;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    margin-right: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.testimonial-carousel .owl-dots .owl-dot.active span::after {
    background: var(--bs-white);
}

.testimonial-carousel .owl-dots .owl-dot span::after {
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: var(--bs-primary);
    transition: 0.5s;
}
/*** Testimonial End ***/

/*** Footer Start ***/
.footer {
    background: var(--bs-dark);
    position: relative;
    z-index: 10;
}   

.footer .footer-item a {
    line-height: 35px;
    color: var(--bs-white);
    transition: 0.5s;
}

.footer .footer-item p {
    line-height: 35px;
}

.footer .footer-item a:hover {
    letter-spacing: 1px;
    color: var(--bs-primary);
}

.footer .footer-item .footer-btn a {
    transition: 0.5s;
}

.footer .footer-item .footer-btn a:hover {
    background: var(--bs-white);
}

.footer .footer-item .footer-btn a:hover {
    color: var(--bs-primary);
}
/*** Footer End ***/

/*** copyright Start ***/
.copyright {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    background: var(--bs-dark);
}
/*** copyright end ***/

.servicei .new1{
    background-size: contain;
}



.parallax {
    position: relative;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px; /* Adjust height as needed */
}
  
.parallax img {
    width: 100%;
    height: auto;
    opacity: 0.8;
}
  
.servicetab {
   
    
}
/* 
.service .servicecont{
    justify-content: center;
    align-items: center;
    bottom: 0;
    width: 100%;
} */
/* 
.service .servicecont .tab-content{
    display: flex;
    bottom: 0;
    width: 100%;
}

.servicecont .tab-content .active{
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
   width: 100%;
    display: flex;
    bottom: 0;
    margin-top: 80px;
    margin-left: 15%;
}

.service .servicecon1{
    z-index: 8;
    position: relative;
}

.service .servicecon2{
    z-index: 7;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.service .servicecon3{
    z-index: 6;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.service .servicecon4{
    z-index: 5;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.service .main-wrap {
    border-radius: var(--radius-32px);
    flex-direction: column;
    width: 100%;
    height: 773px;
    display: flex;
    position: relative;
    overflow: hidden;
}

.service .sticky-wrap {
    margin-top: auto;
    margin-bottom: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 8svh;
}

.service-v2 {
   
        flex-flow: column;
        justify-content: center;
        align-items: center;
        height: 380svh;
      
}
.service-v2d {
   
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 380svh;
  
}*/
.navbar-toggler {
    margin-left: auto;
  } 


  .container-fluid.service{
    background-color: white;
    position: relative;
    z-index: 10;
  } 

  .about{
    position: relative;
    z-index: 999;
    
  }

  .dark-about{
    background-color: black;
  }

  .light-about{
    background-color: white;
    
  }


  .logoimg{
    background: none;
    background-size: cover;
    height: 50px;
    width: 50px;
    left: 0;
  }

  .navbar-brand img{
    width: 100px;
    height: 80px;
  }

  /* .welcomeslideheader1 {
    display: block !important;
  } */
  

  @media only screen and (max-width: 480px) {
  

}

